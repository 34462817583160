/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import ati57 from '../images/favicons/apple-touch-icon-57x57.png';
import ati72 from '../images/favicons/apple-touch-icon-72x72.png';
import ati76 from '../images/favicons/apple-touch-icon-76x76.png';
import ati114 from '../images/favicons/apple-touch-icon-114x114.png';
import ati120 from '../images/favicons/apple-touch-icon-120x120.png';
import ati144 from '../images/favicons/apple-touch-icon-144x144.png';
import ati152 from '../images/favicons/apple-touch-icon-152x152.png';
import ati180 from '../images/favicons/apple-touch-icon-180x180.png';
import favicon from '../images/favicons/favicon.ico';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            image
            name
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={site.siteMetadata.title}
      titleTemplate={`${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:site_name`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:url`,
          content: site.siteMetadata.url
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:url`,
          content: site.siteMetadata.url
        },
        {
          name: `name`,
          content: site.siteMetadata.name
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: ``
        }
      ].concat(meta)}
    >
      <link rel="apple-touch-icon" sizes="57x57" href={ati57} />
      <link rel="apple-touch-icon" sizes="72x72" href={ati72} />
      <link rel="apple-touch-icon" sizes="76x76" href={ati76} />
      <link rel="apple-touch-icon" sizes="114x114" href={ati114} />
      <link rel="apple-touch-icon" sizes="120x120" href={ati120} />
      <link rel="apple-touch-icon" sizes="144x144" href={ati144} />
      <link rel="apple-touch-icon" sizes="152x152" href={ati152} />
      <link rel="apple-touch-icon" sizes="180x180" href={ati180} />
      <link rel="shortcut icon" href={favicon} />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default SEO;
